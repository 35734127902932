<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="evaluate" v-else>
      <b-container fluid>
        <br />
        <b-row>
          <b-col md="12">
            <!-- <p>{{ StudentExamSheet[0] }}</p>
            <p>{{ studentAnswer }}</p> -->
            <div class="loading" v-if="StudentExamSheet">
              <load-data></load-data>
            </div>
            <div v-else>
              <div v-if="studentSheet.length != 0">
                <h4 class="text-center">
                  Evaluation Sheet for {{ exam.name }}
                </h4>
                <div
                  class="studentSheet"
                  v-for="(sheet, sheetIndex) in studentSheet"
                  :key="sheetIndex"
                >
                  <b-card no-body header-tag="header">
                    <template #header>
                      <div class="question">
                        <p>Ques: {{ sheet.question }}</p>
                      </div>
                      <div
                        class="questionAttachments"
                        v-if="sheet.files.length != 0"
                      >
                        <p>Question Attachments:</p>
                        <div
                          class="attachments"
                          v-for="(file, fileIndex) in sheet.files"
                          :key="fileIndex"
                        >
                          <a
                            :href="
                              userAttachments + file.file_path + file.file_name
                            "
                            class="text-decoration-none"
                            target="_blank"
                            style="font-size: 16px"
                          >
                            {{ file.file_name }}
                          </a>
                        </div>
                      </div>
                    </template>
                  </b-card>
                </div>
                <br />

                <div class="answers" v-if="studentAnswer.length !=0">
                   <hr style="background-color: #0275d8; height: 2px">
                  <h3>Answers:</h3>
                  <div
                    v-for="(answer, answerIndex) in studentAnswer"
                    :key="answerIndex"
                  >
                    <p class="text-dark">{{ answer.answer }}</p>
                    <div class="totalScore">
                      <h6 class="font-weight-bolder text-right">
                        {{
                          answer.score != null
                            ? "Total Score: " + answer.score
                            : "Marks not alloted yet!"
                        }}
                      </h6>
                    </div>
                    <div
                      class="answerAttachments"
                      v-if="answer.studentFiles.length != 0"
                    >
                      <p>Answers Attachments:</p>
                      <div
                        class="attachments"
                        v-for="(file, fileIndex) in answer.studentFiles"
                        :key="fileIndex"
                      >
                        <a
                          :href="
                            userAttachments + file.file_path + file.file_name
                          "
                          class="text-decoration-none"
                          target="_blank"
                          style="font-size: 16px"
                        >
                          {{ file.file_name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.fetchData = true;
    this.getExam();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      fetchData: false,
      StudentExamSheet: false,
      userAttachments: "",
      currentMark: "0",
      exam: {},
      students: [],
      examQuestions: [],
      studentSheet: [],
      marksBtn: {
        text: "Submit",
        disabled: false,
      },
      studentAnswer: [],
    };
  },
  methods: {
    getExam() {
      const assignmentId = this.$route.params.assignment_id;
      const url = this.$store.getters.getAPIKey.assignment + `/${assignmentId}`;
      console.log(url);
      axios
        .get(url)
        .then((response) => {
          this.exam = {};
          this.exam = response.data;
          this.getExamQuestions();
          //   this.getCourse();
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getStudentSheet() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/assignments/${this.exam.id}/institutionuser/assignmentresponses`;
      axios
        .get(url)
        .then((response) => {
          // this.studentSheet = [];
          this.studentAnswer = [];
          let answers = response.data;
          console.log(answers);
          if (answers != "") {
            this.studentAnswer.push({
              answer: answers.answer,
              studentId: answers.student_id,
              studentFiles: answers.files,
              score: answers.score != null ? Math.floor(answers.score) : null,
              disabled: answers.score != null ? true : false,
            });
          }

          if (answers == "" || null) {
            setTimeout(() => {
              this.$router.push({ name: "institute" });
            }, 5000);
          }
          this.StudentExamSheet = false;
          this.fetchData = false;
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getExamQuestions() {
      this.StudentExamSheet = true;
      const url =
        this.$store.getters.getAPIKey.assignment +
        `/${this.exam.id}/assignmentquestions`;
      axios
        .get(url)
        .then((response) => {
          this.examQuestions = [];
          this.studentSheet = [];
          this.examQuestions = response.data;
          if (this.examQuestions.length != 0) {
            // this.answers = [];
            this.examQuestions.forEach((answer) => {
              this.studentSheet.push({
                questionId: answer.id,
                // studentId: "",
                questionNumber: answer.question_no,
                question: answer.name,
                // answer: "",
                // marks: "",
                attachment: [],
                files: answer.files,
                // studentFiles: [],
              });
            });
          }
          this.getStudentSheet();
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    clearResponse() {
      this.marksBtn.text = "Submit";
      setTimeout(() => {
        this.getExamQuestions();
        this.marksBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>

<style >
.evaluate .col-md-12 {
  height: 600px;
  overflow-y: scroll;
}
</style>
